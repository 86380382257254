import { EntityType } from './entityType'
import { Button, Col, Container, Row } from 'react-bootstrap';



interface EntityCardProps {
    data: EntityType,
    //parentFunction: Function,
}

const EntityCard = (props: EntityCardProps) => {

    //const OpenURL = (url: string) => {
    const OpenURL = (url: EntityType) => {

        console.log("Opening URL:", url);
        window.open(url.external_uris[0], "_blank");
    }

    return (
        <Container className='entity-card'>
            <Row className="align-items-center">
                <Col>
                    <Container>
                        <Row>
                            <Col>
                                <span className={props.data.label + ' entity-label'}>{props.data.label}</span> {props.data.entity_text}
                            </Col>
                        </Row>
                        <Row>
                            <Col className='entity-sub-row'>
                                {props.data.score &&
                                    <span title={props.data.score.toString()}>{props.data.entity_name} &#183; Confidence score: {props.data.score_norm}%</span>
                                }
                            </Col>
                            </Row>
                    </Container>
                </Col>
                <Col xs={4}>
                    { props.data.external_uris &&
                    <Button variant="secondary" size="sm" onClick={() => OpenURL( props.data )}>Open Wikidata</Button>
                    }
                </Col>
            </Row>
        </Container>

    );
};

export default EntityCard;